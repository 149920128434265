const hamburger = document.querySelector(".hamburger");
hamburger.addEventListener("click", function () {
  hamburger.classList.toggle("is-active");
});

document.addEventListener("DOMContentLoaded", function () {
  // get height of header
  const header = document.getElementById("site-header");
  const headerHeight = header.offsetHeight;
  console.log("header-height", headerHeight);
  // append padding to body to prevent content from being hidden behind header
  const mobileNavigationBody = document.getElementById(
    "mobile-navigation-body"
  );
  mobileNavigationBody.style.paddingTop = headerHeight + 24 + "px";
});
